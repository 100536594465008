<template>
  <Popup
    title="소득공제신청 상태변경"
    :maxWidth="800"
    closeBtnText="닫기"
    :compeleteBtnText="isUnChanged ? '기존상태와 동일':'상태변경'"
    :isDisabeldCompeleteBtn="isUnChanged"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete',changedStatus)">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:160px"/>
        <col/>
        <col style="width:160px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">신청 정보</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>ID</th>
          <td colspan="3">{{ detailData.id }}</td>
        </tr>
        <tr>
          <th>user ID</th>
          <td>{{ detailData.userId }}</td>
          <th>user UID</th>
          <td>{{ detailData.userUid }}</td>
        </tr>
        <tr>
          <th>우편받는이</th>
          <td>{{ detailData.receiverName }}</td>
          <th>자녀이름</th>
          <td>{{ detailData.name }}</td>
        </tr>
        <tr>
          <th>생년월일</th>
          <td>{{ detailData.dateOfBirth | convertDateFormat('YYYY-MM-DD') }}</td>
          <th>전화번호</th>
          <td>{{ detailData.phoneNumber | convertPhoneNember}}</td>
        </tr>
        <tr>
          <th>상태</th>
          <td colspan="3">
            <StatusSelect
              :dataList="'cs_taxreturn_status' | getSelectDataList"
              :status="detailData.status"/>
          </td>
        </tr>
        <tr>
          <th>상태변경일시</th>
          <td colspan="3">{{ detailData.updatedAt | convertDateFormat}}</td>
        </tr>
        <tr>
          <th>신청일시</th>
          <td>{{ detailData.registerAt | convertDateFormat }}</td>
          <th>접수일시</th>
          <td>{{ detailData.processAt | convertDateFormat}}</td>
        </tr>
        <tr>
          <th>주소</th>
          <td colspan="3">{{ detailData.address }}</td>
        </tr>
        <tr>
          <th>상세주소</th>
          <td>{{ detailData.detailAddress }}</td>
          <th>우편번호</th>
          <td>{{ detailData.zipCode }}</td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:160px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">상태변경</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>변경할 상태</th>
          <td>
            <Radio
              :dataList="changableStatusDataList"
              :value.sync="changedStatus"/>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import StatusSelect from '@lemontree-ai/lemontree-admin-common-front/components/common/statusSelect/StatusSelect';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';

import { getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils';
// viewModel
import TaxReturnListViewModel from '@/views/cs/taxReturn/viewModel/TaxReturnListViewModel'

export default {
  name:'StatusChangePopup',
  props:{
    detailData: Object
  },
  components:{
    Popup,
    TableView,
    StatusSelect,
    Radio
  },
  beforeMount(){
    this.changedStatus = this.detailData.status;
    this.setChangableStatusDataList();
  },
  computed:{
    isUnChanged(){
      return this.detailData.status === this.changedStatus;
    }
  },
  data(){
    return{
      viewModel: new TaxReturnListViewModel(),
      changableStatusDataList: [],
      changedStatus:''
    }
  },
  methods:{
    setChangableStatusDataList(){
      this.changableStatusDataList = getSelectDataList('cs_taxreturn_status').map((item) => {
        // if(item.id == this.detailData.status){
        //   item.isDisabled = true;
        // }
        return item
      })
    }
  }
}
</script>