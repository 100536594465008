<template>
  <PageWithLayout>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      :emptyDesc="viewModel.TAX_RETURN.searched && !viewModel.TAX_RETURN.hasData ? '검색결과가 없습니다' : '반송 기간을 검색해주세요'"
      @onSearch="viewModel.onSearch()"
      @onClickRow="(id,rowData) => viewModel.onClickRow(id,rowData)">
    </Board>
    <template v-slot:popup>
      <StatusChangePopup
        v-if="viewModel.isStatusChangePopup"
        :detailData="viewModel.detailData"
        @onClickClose="viewModel.onClickCloseStatusChangePopup()"
        @onClickComplete="(changedStatus) => viewModel.onClickCompleteStatusChangePopup(changedStatus)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
// popup
import StatusChangePopup from '@/views/cs/taxReturn/view/popup/StatusChangePopup'
// viewModel
import TaxReturnListViewModel from '@/views/cs/taxReturn/viewModel/TaxReturnListViewModel'

export default {
  name: 'TaxReturnList',
  components:{
    PageWithLayout,
    Board,

    StatusChangePopup
  },
  beforeMount(){
    this.viewModel.init()
  },
  data(){
    return{
      viewModel: new TaxReturnListViewModel(),
    }
  },
}
</script>
<style scoped>
.board_comm::v-deep .tbl_comm table{min-width:100%;width:auto}
.board_comm::v-deep .tbl_comm table .td_mark .txt_tbl{display:inline-block}
</style>