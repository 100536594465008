import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';
import { GET_REQUEST, PUT_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { convertIdToText } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils';

export default class TaxReturnListViewModel {
  constructor() {
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.boardData = {
      title:'소득공제 신청자 목록',
      drawDataList: [
        {
          title:'ID',
          width:'80px',
          value:'id',
          isAlignLeft: true,
        },
        {
          title:'user ID',
          width:'68px',
          value:'userId',
          isAlignLeft: true,
        },
        {
          title:'user UID',
          width:'180px',
          value:'userUid',
          isAlignLeft: true,
        },
        {
          title:'상태',
          // width:'310px',
          width:'90px',
          value:'status',
          filter: {
            name:'convertIdToText',
            value:'cs_taxreturn_status'
          },
          // statusSelect:{
          //   dataList:'cs_taxreturn_status',
          // },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'cs_taxreturn_status'
            },
          },
        },
        {
          title:'우편받는이',
          width:'80px',
          value:'receiverName',
          isAlignLeft: true,
        },
        {
          title:'자녀이름',
          width:'74px',
          value:'name',
          isAlignLeft: true,
        },
        {
          title:'전화번호',
          width:'120px',
          value:'phoneNumber',
          isAlignLeft: true,
          filter: {
            name:'convertPhoneNember',
          },
        },
        {
          title:'우편번호',
          width:'64px',
          value:'zipCode',
          isAlignLeft: true,
        },
        {
          title:'주소',
          value:'address',
          isAlignLeft: true,
          valueCustom:[
            {
              type: 'value',
              value:'address',
            },
            { 
              type: 'text',
              value: '<br>'
            },
            {
              type: 'value',
              value:'detailAddress',
            },
          ]
        },
      ],
      option:{
        isTotal: true,
        isSize: true,
        rowIdValue: 'id'
      },
    }

    this.isStatusChangePopup = false;
    this.detailData = {};
    this.changedStatus= '';

    this.TAX_RETURN ={
      searched: false,
      hasData: false,
      responseDate:'',
    }
    this.cardDeliveryInfoChange = {
      phone:'',
      address:{
        addressType: "지번 주소:NUMBER_ADDRESS / 도로명 주소:ROAD_ADDRESS",
        address: "string",
        detailAddress: "string",
        zipCode: "string",
        numberAddress: "string",
        roadAddress: "string",
        buildingName: "string",
        isApartment: true,
        sido: "string",
        sigugun: "string",
        dong: "string",
        addressCategory: "집:HOME / 기타:ETC"
      },
    }
  }
  init(){
    this.getTaxReturnCertificate()
  }
  onSearch(){
    this.getTaxReturnCertificate()
  }
  onClickRow(id,rowData){
    this.isStatusChangePopup = true;
    this.detailData = rowData;
  }
  onClickCloseStatusChangePopup(){
    this.isStatusChangePopup = false;
  }
  onClickCompleteStatusChangePopup(changedStatus){
    const requestChange = () => {
      this.isStatusChangePopup = false;
      this.putTaxReturnCertificateStatusChange(this.detailData.id, changedStatus);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `신청서의 상태를 변경하시겠습니까?<br>${convertIdToText(this.detailData.status, 'cs_taxreturn_status')} → ${convertIdToText(changedStatus, 'cs_taxreturn_status')}`,
      closeBtnText: '취소',
      compeleteBtnText: '변경',
      confirmCallBack: requestChange,
    });
  }
  // [API] 소득공제신청서
  getTaxReturnCertificate(){
    this.TAX_RETURN.searched = true;
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.CS_TAX_RETURN_CERTIFICATE}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.data;
      this.TAX_RETURN.hasData = resultData.total > 0;

      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  
  // [API] 신청서 상태 변경
  putTaxReturnCertificateStatusChange(id, status){
    const path = `${apiPath.CS_TAX_RETURN_STATUS.format(id , status)}`;
    PUT_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '신청서의 상태가 변경되었습니다');
      this.getTaxReturnCertificate();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}